var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-25 div-center"},[_c('b-form-checkbox',{staticClass:"mr-2",attrs:{"id":`checkbox-create-t${_vm.Table.id}-a${_vm.Access.id}`,"name":"checkbox-create"},model:{value:(_vm.table_access.can_create),callback:function ($$v) {_vm.$set(_vm.table_access, "can_create", $$v)},expression:"table_access.can_create"}},[_c('h6',[_vm._v("CREAR")])])],1),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"d-flex"},[(_vm.show_can_read)?_c('div',{staticClass:"w-100"},[_c('h6',{staticClass:"text-center"},[_vm._v("VER")]),_c('b-input-group',{staticClass:"mb-1"},[_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                `
                ${
                  !_vm.checkbox_1
                    ? 'Seleccionar todos (incluyendo los que se creen posteriormente)'
                    : 'Deseleccionar todos'
                }
              `
              ),expression:"\n                `\n                ${\n                  !checkbox_1\n                    ? 'Seleccionar todos (incluyendo los que se creen posteriormente)'\n                    : 'Deseleccionar todos'\n                }\n              `\n              ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":`checkbox-1-t${_vm.Table.id}-a${_vm.Access.id}`,"name":"checkbox-1"},on:{"change":() => {
                    if (_vm.checkbox_1) _vm.can_read = _vm.Items.map((x) => x.id);
                    else _vm.can_read = [];
                  }},model:{value:(_vm.checkbox_1),callback:function ($$v) {_vm.checkbox_1=$$v},expression:"checkbox_1"}})],1),_c('b-form-input',{attrs:{"type":"search","size":"sm","disabled":_vm.checkbox_1,"placeholder":`Buscar ${_vm.$getVisibleNames(
                _vm.Table.name,
                false
              )}...`},model:{value:(_vm.input_search1),callback:function ($$v) {_vm.input_search1=$$v},expression:"input_search1"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.input_search1,"size":"sm"},on:{"click":function($event){_vm.input_search1 = ''}}},[_vm._v("Limpiar")])],1)],1),_c('b-form-select',{attrs:{"disabled":_vm.checkbox_1,"options":_vm.searchItems1,"text-field":"name","value-field":"id","size":"sm","select-size":_vm.searchItems1.length < 7 ? _vm.searchItems1.length : 7,"multiple":""},model:{value:(_vm.can_read),callback:function ($$v) {_vm.can_read=$$v},expression:"can_read"}})],1):_vm._e(),_c('div',{staticClass:"ml-2 w-100"},[_c('h6',{staticClass:"text-center"},[_vm._v("EDITAR")]),_c('b-input-group',{staticClass:"mb-1"},[_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                `
                ${!_vm.checkbox_2 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}
              `
              ),expression:"\n                `\n                ${!checkbox_2 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}\n              `\n              ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":`checkbox-2-t${_vm.Table.id}-a${_vm.Access.id}`,"name":"checkbox-2"},on:{"change":() => {
                    if (_vm.checkbox_2) _vm.can_update = _vm.Items.map((x) => x.id);
                    else _vm.can_update = [];
                  }},model:{value:(_vm.checkbox_2),callback:function ($$v) {_vm.checkbox_2=$$v},expression:"checkbox_2"}})],1),_c('b-form-input',{attrs:{"type":"search","size":"sm","disabled":_vm.checkbox_2,"placeholder":`Buscar ${_vm.$getVisibleNames(
                _vm.Table.name,
                false
              )}...`},model:{value:(_vm.input_search2),callback:function ($$v) {_vm.input_search2=$$v},expression:"input_search2"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.input_search2,"size":"sm"},on:{"click":function($event){_vm.input_search2 = ''}}},[_vm._v("Limpiar")])],1)],1),_c('b-form-select',{attrs:{"disabled":_vm.checkbox_2,"options":_vm.searchItems2,"text-field":"name","value-field":"id","size":"sm","select-size":_vm.searchItems2.length < 7 ? _vm.searchItems2.length : 7,"multiple":""},model:{value:(_vm.can_update),callback:function ($$v) {_vm.can_update=$$v},expression:"can_update"}})],1),_c('div',{staticClass:"ml-2 w-100"},[_c('h6',{staticClass:"text-center"},[_vm._v("ELIMINAR")]),_c('b-input-group',{staticClass:"mb-1"},[_c('b-input-group-append',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(
                `
                ${!_vm.checkbox_3 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}
              `
              ),expression:"\n                `\n                ${!checkbox_3 ? 'Seleccionar Todos' : 'Deseleccionar Todos'}\n              `\n              ",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}]},[_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"id":`checkbox-3-t${_vm.Table.id}-a${_vm.Access.id}`,"name":"checkbox-3"},on:{"change":() => {
                    if (_vm.checkbox_3) _vm.can_delete = _vm.Items.map((x) => x.id);
                    else _vm.can_delete = [];
                  }},model:{value:(_vm.checkbox_3),callback:function ($$v) {_vm.checkbox_3=$$v},expression:"checkbox_3"}})],1),_c('b-form-input',{attrs:{"type":"search","size":"sm","disabled":_vm.checkbox_3,"placeholder":`Buscar ${_vm.$getVisibleNames(
                _vm.Table.name,
                false
              )}...`},model:{value:(_vm.input_search3),callback:function ($$v) {_vm.input_search3=$$v},expression:"input_search3"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"disabled":!_vm.input_search3,"size":"sm"},on:{"click":function($event){_vm.input_search3 = ''}}},[_vm._v("Limpiar")])],1)],1),_c('b-form-select',{attrs:{"disabled":_vm.checkbox_3,"options":_vm.searchItems3,"text-field":"name","value-field":"id","size":"sm","select-size":_vm.searchItems3.length < 7 ? _vm.searchItems3.length : 7,"multiple":""},model:{value:(_vm.can_delete),callback:function ($$v) {_vm.can_delete=$$v},expression:"can_delete"}})],1),_c('div',{staticClass:"ml-2 d-flex align-center"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.auto.noninteractive",value:(`Guardar Permisos`),expression:"`Guardar Permisos`",modifiers:{"v-secondary":true,"auto":true,"noninteractive":true}}],attrs:{"size":"sm","variant":"none"},on:{"click":_vm.saveAccessTable}},[_c('b-icon-box-arrow-in-down',{staticClass:"h2 mb-0",attrs:{"font-scale":"1.7"}})],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }